import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import "../styles/program.scss"
import Person from "../components/person"

const Program = ({ data }) => (
  <Layout seoMetaTags={data?.datoCmsProgrampage?.seoMetaTags} page="Ohjelma">
    {data.datoCmsProgrampage?.notificationNode && (
      <div
        className="pageNotification"
        dangerouslySetInnerHTML={{
          __html:
            data.datoCmsProgrampage?.notificationNode.childMarkdownRemark.html,
        }}
      ></div>
    )}{" "}
    {console.log(data)}
    <div className="program">
      {data?.datoCmsProgrampage?.date.map(date => {
        return date.__typename === "DatoCmsDate" ? (
          <h2 key={date.id}>{date.dateAndTime}</h2>
        ) : (
          <div key={date.id} className="timeSlot">
            <div className="content">
              <h3>KLO {date.time}</h3>
              <div className="speakers">
                {date.speakers?.map(person => (
                  <Person {...person} />
                ))}
              </div>
              {date.detailsNode && (
                <div
                  className="details"
                  dangerouslySetInnerHTML={{
                    __html: date.detailsNode.childMarkdownRemark.html,
                  }}
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  </Layout>
)
export const query = graphql`
  query ProgramQuery {
    datoCmsProgrampage {
      notificationNode {
        childMarkdownRemark {
          html
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      date {
        ...timeSlotRecord
        ...dateFields
      }
    }
  }
  fragment dateFields on DatoCmsDate {
    id
    dateAndTime
    __typename
  }
  fragment timeSlotRecord on DatoCmsTimeSlot {
    id
    speakers {
      ...personFields
    }
    detailsNode {
      childMarkdownRemark {
        html
      }
    }
    time
  }
`

export default Program
